
/* izitelo_logo */
.izitelo_logo {
  width: 11vw;
  height: auto; 
}

/* landing page */
#row1 {
  padding-top: 1.7vw;
}

#row1_2 {
  padding-top: 3vw;
}

  /* Trait avant l'effet */
  .trait_icon {
      size: 1vw;
  }
  /* Effet de rotation de texte */
  .presentation {
      font-weight: bold;
      font-size: 20px;
      }

#row2_1 {
  margin-left: 3vw;
  margin-top: 2vw;
  padding: 2vw;    
  background-color: #f6f8f8;
  width: 75%;
  height: 100%;
  border-radius: 10%;    
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

  /* row2_2 responsivity for 760px and smaller */
  @media screen and (max-width: 760px) {
      #row2_1 {
          margin-top: 7vw; 
      }
  }

#form_title {
  margin-bottom: 15px;
  text-align: center;
}

#trait {
  width: 10vw;
  height: 5px;
  background-color: #059D6E;
  margin: auto;
  margin-bottom: 10%;
}

/* form */
form {
 padding: 1vw;
 font-family: 'Trebuchet MS', sans-serif;
 font-size: 75%;
 color: 	#475C56;

}
.form_group input {
  width: 100%;
}

.btn {
  width: 100%;
  background-color: #059D6E;
  color : white;
  font-weight: bold;
}

/* Second section */
section {
margin-top: 30px;
background-image: url("../public/img/wave.svg");
aspect-ratio: 960/300;
width: 100%;
background-repeat: no-repeat;
background-position: center;
background-size: cover;
}

  /* box responsivity for 760px and larger */
  @media screen and (min-width: 500px) {
      section {
          display: flex;
          justify-content: center;
      }
  }

.box {
text-align: center;
font-size: 23px;
background-color: white;
box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
border-radius: 10%; 
padding-top: 2%;
width: 15%;   
}
.box::after {
content: "";
display: block;
padding-bottom: 20%;
}

    /* box responsivity for 760px and larger */
    @media screen and (min-width: 500px) {
      .box {   
       margin: auto;
      }
  }


/* Transition */
.transition {
  display: flex;
  justify-content: center;
}
.avant_titre {
  margin-top: 7%;
  color:#00BAAE;
}
.titre {
  margin-top: 0;
  font-size : 40px;
  font-weight: bold;
}

/* services */
#services {
padding-top: 4%;
}

/* 3rd section */
/* icon on 3rd section */
.icon {
  width: 7vw;
  height: auto; 
}
