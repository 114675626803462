/* form */
form {
    padding: 0.5vw;    
   }

   label {
    font-family: 'Trebuchet MS', sans-serif;
    font-size: 100%;
    color: 	#475C56;   
   }

   input, textarea {
     width: 100%;
     margin-bottom: 10px;
   }
   
   .btn {
     width: 100%;
     background-color: #00BAAE ;
     color : white;
     font-weight: bold;
   }

   a {
    text-decoration: none;
    color:inherit;
   }